import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import ShowPassword from '../../images/authorize/showPassword.svg';
import HidePassword from '../../images/authorize/hidePassword.svg';

const StyledLabel = styled.label`
	display: flex;
	flex-direction: column;
	margin-top: 2rem;
`;

const StyledLabelText = styled.span`
	align-items: center;
	color: ${({ theme }) => theme.colors.fontBlue};
	display: flex;
	font-size: 0.75rem;
	margin-bottom: 0.35rem;
	text-transform: lowercase;
	&::before {
		background-color: ${({ theme }) => theme.colors.aqua};
		border-radius: 100%;
		content: '';
		display: block;
		height: 4px;
		margin-right: 0.5rem;
		width: 4px;
	}
	&.error {
		color: ${({ theme }) => theme.colors.errorOrange};
  	}
`;

const StyledInput = styled.input`
	-webkit-appearance: none;
	appearance: none;
	background: ${({ theme }) => theme.colors.dirtyAqua};
	border: 3px solid ${({ theme }) => theme.colors.aqua};
	border-radius: 18px;
	box-shadow: none;
	color: ${({ theme }) => theme.colors.lightBlue};
	font-size: 12px;
	font-weight: 700;
	height: 48px;
	margin: 0;
	margin-top: 15px;
	outline: none;
	padding: 0 1.5rem;
  	vertical-align: middle;
	width: 100%;
	&.error {
		background-color: ${({ theme }) => theme.colors.lightErrorOrange};
		border: 2px solid ${({ theme }) => theme.colors.errorOrange};
	}
`;
const SendBtn = styled.img`
	-webkit-tap-highlight-color: transparent;
	cursor: pointer;
  	position: absolute;
  	right: 16px;
 	top: 22px;
`;
const InputWrapper = styled.div`
	display: flex;
  	flex-direction: row;
  	margin-bottom: 20px;
  	position: relative;
`;

type PasswordInputProps = {
	label: string,
	errorVisible: boolean,
	onKeyDown: (e) => void,
	setPassword: (e) => void,
	pushInputToTop: (e) => void,
}

const PasswordInput: FunctionComponent<PasswordInputProps> = ({
	label, setPassword, errorVisible, onKeyDown, pushInputToTop,
}) => {
	const [isPasswordVisible, setPasswordVisible] = useState(false);

	const handleShowPassword = () => {
		setPasswordVisible(!isPasswordVisible);
	};

	return (
		<StyledLabel>
			<StyledLabelText className={errorVisible ? 'error' : ''}>
				{label}
			</StyledLabelText>
			<InputWrapper>
				<StyledInput
					className={errorVisible ? 'error' : ''}
					maxLength={250}
					onChange={setPassword}
					onClick={pushInputToTop}
					onKeyDown={onKeyDown}
					type={!isPasswordVisible ? 'password' : 'text'}
				/>
				<SendBtn
					onClick={handleShowPassword}
					src={!isPasswordVisible ? ShowPassword : HidePassword}
				/>
			</InputWrapper>
		</StyledLabel>
	);
};

export default PasswordInput;
